module.exports = `
  type Theme {
    theme_id: Int
    name: String
    preview_url: String 
    done: Boolean
  }
 
  type ThemeColor {
    theme_color_id: Int 
    theme_id: Int
    name: String
    value: String
    done: Boolean
  }

  input ThemeColorInput {
    theme_color_id: Int
    value: String
  }
`;
