const Article = {
  title: 'tytuł artykułu',
  meta_title: 'META_TITLE',
  meta_description: 'META_DESCRIPTION',
  url_canonic: 'URL_CANONIC',
  category: 'Nazwa kategorii',
  image:
    'https://images.unsplash.com/photo-1529530385195-5f7be6bb3d12?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1396&h=650&q=60',
  data: `
  <p>sed do eiusmod tempor incididunt ut labore et <b>dolore magna aliqua</b>. Duis <u>convallis convallis tellus</u> id <a href="#"> link </a> interdum velit laoreet id donec. Sed cras ornare arcu dui vivamus arcu. Feugiat in fermentum posuere urna nec tincidunt praesent. Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a. Consequat nisl vel pretium lectus quam id leo in vitae. Venenatis urna cursus eget nunc scelerisque viverra mauris in. Amet venenatis urna cursus eget nunc scelerisque viverra mauris in. Tincidunt vitae semper quis lectus nulla at volutpat diam ut. Suspendisse ultrices gravida dictum fusce ut placerat. Tellus at urna condimentum mattis pellentesque id nibh. Non arcu risus quis varius quam quisque. At urna condimentum mattis pellentesque id. Blandit cursus risus at ultrices mi tempus imperdiet. Eget nunc scelerisque viverra mauris in aliquam sem. Ultrices sagittis orci a scelerisque purus semper eget. Amet risus nullam eget felis eget nunc lobortis. Consectetur adipiscing elit pellentesque habitant morbi tristique. Hendrerit dolor magna eget est lorem ipsum dolor. Elementum facilisis leo vel fringilla.</p>
    [customgallery title="Przykladowa galeria 1"]5[/customgallery]
    [gallery title="Przykladowa galeria 2"]5[/gallery]
    [galeria title="Przykladowa galeria 3"]5[/galeria]
    <p>sed do eiusmod tempor incididunt ut labore et <b>dolore magna aliqua</b>. Duis <u>convallis convallis tellus</u> id interdum velit laoreet id donec. Sed cras ornare arcu dui vivamus arcu. Feugiat in fermentum posuere urna nec tincidunt praesent. Nibh sit amet commodo nulla facilisi nullam vehicula ipsum a. Consequat nisl vel pretium lectus quam id leo in vitae. Venenatis urna cursus eget nunc scelerisque viverra mauris in. Amet venenatis urna cursus eget nunc scelerisque viverra mauris in. Tincidunt vitae semper quis lectus nulla at volutpat diam ut. Suspendisse ultrices gravida dictum fusce ut placerat. Tellus at urna condimentum mattis pellentesque id nibh. Non arcu risus quis varius quam quisque. At urna condimentum mattis pellentesque id. Blandit cursus risus at ultrices mi tempus imperdiet. Eget nunc scelerisque viverra mauris in aliquam sem. Ultrices sagittis orci a scelerisque purus semper eget. Amet risus nullam eget felis eget nunc lobortis. Consectetur adipiscing elit pellentesque habitant morbi tristique. Hendrerit dolor magna eget est lorem ipsum dolor. Elementum facilisis leo vel fringilla.</p>
    <blockquote>
    <p>
    Tortor consequat id porta <small>nibh venenatis</small>. Enim praesent elementum facilisis leo vel fringilla. Dignissim diam quis enim lobortis scelerisque fermentum. Viverra adipiscing at in tellus integer feugiat scelerisque varius. Ac turpis egestas sed tempus urna et. Et ultrices neque ornare aenean. Enim blandit volutpat maecenas volutpat blandit aliquam. Cursus sit amet dictum sit amet justo donec enim diam.
    </p>
    </blockquote>
    <p>Tortor<sup> consequat id porta nibh venenatis</sup>. Enim praesent elementum facilisis leo vel fringilla. Dignissim diam quis enim lobortis scelerisque fermentum. Viverra adipiscing at in tellus integer feugiat scelerisque varius. Ac turpis egestas sed tempus urna et. Et ultrices neque ornare aenean. Enim blandit volutpat maecenas volutpat blandit aliquam. Cursus sit amet dictum sit amet justo donec enim diam. Vitae et leo duis ut diam quam nulla. Nisl nunc mi ipsum faucibus vitae. Dolor sed viverra ipsum nunc aliquet bibendum. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae auctor. Diam quam nulla porttitor massa id neque aliquam vestibulum. A diam maecenas sed enim ut sem viverra aliquet eget. Ac ut consequat semper viverra nam. Vulputate mi sit amet mauris commodo. Risus feugiat in ante metus dictum at. Neque convallis a cras semper auctor. Quam adipiscing vitae proin sagittis</p>
    <br/>
    <br/>
    <br/>
    <br/>
    <h2>Lorem ipsum <mark>dolor sit amet</mark>, <del>consectetur</del> adipiscing elit</h2>
    <p>Nibh tellus molestie nunc non. Proin nibh nisl condimentum id venenatis a condimentum vitae. Ultrices tincidunt arcu non sodales. Quisque id diam vel quam elementum pulvinar etiam. Blandit libero volutpat sed cras ornare. Gravida rutrum quisque non tellus orci ac. Adipiscing commodo elit at imperdiet dui accumsan sit. Tincidunt augue interdum velit euismod in pellentesque massa. Quisque id diam vel quam elementum pulvinar. Leo in vitae turpis massa sed elementum tempus egestas sed. Libero nunc consequat interdum varius sit amet mattis vulputate. Odio ut enim blandit volutpat maecenas volutpat. Dolor magna eget est lorem ipsum dolor sit amet consectetur. Scelerisque varius morbi enim nunc faucibus a. Aliquam etiam erat velit scelerisque in dictum non consectetur.</p>

    <ul>
    <li>item 1</li>
    <li>
    <ol>
    <li>item 1 a</li>
    <li>item 1 b</li>
    </ol>
    </li>
    <li>item 2</li>
    </ul>

    <ul>
    <li>item 1</li>

    <ol>
    <li>item 1 a</li>
    <li>item 1 b</li>
    </ol>

    <li>item 2</li>
    </ul>

    <ol>
    <li>item 1</li>
    <li>
    <ol>
    <li>item 1 a</li>
    <li>item 1 b</li>
    </ol>
    </li>
    <li>item 2</li>
    </ol>

    <ol>
    <li>item 1</li>
    <li>
    <ul>
    <li>item 1 a</li>
    <li>item 1 b</li>
    </ul>
    </li>
    <li>item 2</li>
    </ol>
    <table>
    <tr>
    <th>Title 1</th>
    <th>Title 2</th>
    </tr>
    <tr>
      <td>item 1</td>
      <td>item 2</td>
    </tr>
    <tr>
      <td>item 1</td>
      <td>item 2</td>
    </tr>
    </table>
    <blockquote>
    <p>
    Tortor consequat id porta <small>nibh venenatis</small>.
    </p>
    </blockquote>
    `,
  gallery: {
    title: 'Bardzo dlugi tytul galerii',
    images: [
      {
        title: 'Bardzo dluga nazwa obrazka',
        image:
          'https://images.unsplash.com/photo-1559308448-de7de9315f9b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9',
        thumbnail:
          'https://images.unsplash.com/photo-1559308448-de7de9315f9b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=230&h=230&q=60'
      },
      {
        title: 'Bardzo dluga nazwa obrazka 2',
        image:
          'https://images.unsplash.com/photo-1504169448388-60f322bebb2c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9',
        thumbnail:
          'https://images.unsplash.com/photo-1504169448388-60f322bebb2c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=230&h=230&q=60'
      },
      {
        title: 'Bardzo dluga nazwa obrazka 3',
        image:
          'https://images.unsplash.com/photo-1485322551133-3a4c27a9d925?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9',
        thumbnail:
          'https://images.unsplash.com/photo-1485322551133-3a4c27a9d925?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=230&h=230&q=60'
      },
      {
        title: 'Bardzo dluga nazwa obrazka 4',
        image:
          'https://images.unsplash.com/photo-1460518451285-97b6aa326961?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9',
        thumbnail:
          'https://images.unsplash.com/photo-1460518451285-97b6aa326961?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=230&h=230&q=60'
      },
      {
        title: 'Bardzo dluga nazwa obrazka 5',
        image:
          'https://images.unsplash.com/photo-1496317899792-9d7dbcd928a1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9',
        thumbnail:
          'https://images.unsplash.com/photo-1496317899792-9d7dbcd928a1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=230&h=230&q=60'
      },
      {
        title: 'Bardzo dluga nazwa obrazka 6',
        image:
          'https://images.unsplash.com/photo-1506808541308-577f3be75bb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9',
        thumbnail:
          'https://images.unsplash.com/photo-1506808541308-577f3be75bb7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=230&h=230&q=60'
      },
      {
        title: 'Bardzo dluga nazwa obrazka 7',
        image:
          'https://images.unsplash.com/photo-1426647451887-5f2be01918a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9',
        thumbnail:
          'https://images.unsplash.com/photo-1426647451887-5f2be01918a9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=230&h=230&q=60'
      }
    ]
  }
};

export { Article };
export default { Article };
