import React, { createContext, useState, useEffect, useContext } from 'react';
import { createTheming } from '@callstack/react-theme-provider';
import { useCookies } from 'react-cookie';

import { AppSettingsContext } from 'AppSettingsContext';
import config from 'config';
import applicationThemes from 'assets/Themes';
import getOverride from 'assets/ThemeColorsOverride';

const { ThemeProvider, useTheme } = createTheming(
  applicationThemes.themeDefault
);

const Context = createContext([{}, function() {}]);

const ThemeProviderWrapper = ({ children }) => {
  const [appSettings] = useContext(AppSettingsContext);
  const [cookies, setCookie] = useCookies(['APP_THEME']);
  const [theme, setTheme] = useState(
    parseInt(cookies['APP_THEME']) || config.theme
  );

  const currentTheme = config.themes.find(availableTheme => {
    return theme
      ? availableTheme.name === theme
      : availableTheme.name === config.theme;
  });

  const currentThemeData =
    applicationThemes[
      (currentTheme && currentTheme.theme) ||
        (config.themes[0] && config.themes[0].theme)
    ];

  const setNestedParam = (object, keys, value) => {
    if (keys && keys.length > 0) {
      const currentKey = keys.shift();
      if (currentKey && object[currentKey]) {
        if (keys && keys.length > 0)
          return setNestedParam(object[currentKey], keys, value);
        return (object[currentKey] = value);
      }
    }
    return undefined;
  };

  const overrideTheme = theme => {
    console.log('appSettings: ', appSettings);
    let themeColorsOverride = getOverride(appSettings.theme);
    appSettings.themeColors.forEach(color => {
      if (
        color.value &&
        color.theme_color_id &&
        themeColorsOverride[color.theme_color_id - 1]
      ) {
        themeColorsOverride[color.theme_color_id - 1].forEach(overrideKey => {
          const keys = overrideKey.split('.');
          if (color.value) setNestedParam(theme, keys, color.value);
        });
      }
    });
    return theme;
  };

  useEffect(() => {
    if (theme !== cookies['APP_THEME'])
      setCookie('APP_THEME', theme, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365
      });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  useEffect(() => {
    if (appSettings.themeColors && appSettings.themeColors.length > 0)
      overrideTheme(currentThemeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme, appSettings.themeColors]);

  return (
    <Context.Provider value={{ theme, switchTheme: setTheme }}>
      <ThemeProvider theme={currentThemeData}>{children}</ThemeProvider>
    </Context.Provider>
  );
};

export { ThemeProviderWrapper, useTheme, Context as ThemeContext };
