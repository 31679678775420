module.exports = `
  type Query {
    getSuperadmin: [Superadmin]
    getWidget(widget_id: Int!): [Widget]
    getPortal: Portal
    getBanner: [News]
    getGallery(url: String, gallery_id:Int): Gallery
    getGalleryList(category: String, page: Int!): ArticleList
    getGalleryCategory(gallery_category_id:Int,url:String): GalleryCategory
    getWidgets(type: String): [Widget]
    getWidgetArea(widget_area_id: Int!): WidgetArea
    getNewsGrid: [NewsList]
    getNewsList(news_category_name: String, offset: Int, limit: Int, page:Int): NewsList
    getNews(url: String,news_category_url:String): News
    getPage(url: String): Page
    searchResult(search: String!,limit:Int,page:Int,offset:Int,page:Int): ArticleList
  }
`;
