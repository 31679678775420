const ArticlesList = {
  title: 'Pierwsza kategoria artykułów',
  slug: 'przykladowa-kategoria',
  pages: 50,
  articles: [
    {
      title:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      text:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      slug: '/aktualnosci/przykladowy-pierwszy-artykul',
      image:
        'https://images.unsplash.com/flagged/photo-1560408582-aabf80b149a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&h=188&q=60'
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      text:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      slug: '/aktualnosci/przykladowy-drugi-artykul',
      image:
        'https://images.unsplash.com/photo-1509062522246-3755977927d7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&h=188&q=60',
      isFeatured: true
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      text:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      slug: '/aktualnosci/przykladowy-trzeci-artykul',
      image:
        'https://images.unsplash.com/photo-1495727034151-8fdc73e332a8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&h=188&q=60',
      isFeatured: true
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      text:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      slug: '/aktualnosci/przykladowy-czwarty-artykul',
      image:
        'https://images.unsplash.com/photo-1544776193-32d404ae608a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&h=188&q=60'
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      text:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      slug: '/aktualnosci/przykladowy-piaty-arykul',
      image:
        'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&h=188&q=60'
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      text:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      slug: '/aktualnosci/przykladowy-szosty-artykul',
      image:
        'https://images.unsplash.com/photo-1549980384-d61217e50c4b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&h=188&q=60'
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      text:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      slug: '/aktualnosci/przykladowy-siodmy-artykul',
      image:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&h=188&q=60'
    },
    {
      title:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      text:
        'Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua Lorem ipsum dolor sit amet, consecteur adipiscing elit, et dolore magna aliqua',
      slug: '/aktualnosci/przykladowy-osmy-artykul',
      image:
        'https://images.unsplash.com/photo-1495968283540-e1df41995ba6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&h=188&q=60'
    }
  ]
};

export { ArticlesList };
export default { ArticlesList };
