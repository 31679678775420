 module.exports = `
  type ArticleList {
    max_count:Int
    pages: Int
    category: ArticleCategory 
    articles: [Article]
  }
 
  type ArticleCategory {
    gallery_category_id: Int
    news_category_id: Int
    category_id: Int
    name: String
    active: Boolean
    done: Boolean
  }
 
  type Article {
    type: String
    id: Int
    category_id: Int
    news_category_id: Int
    name: String
    meta_title: String
    meta_description: String
    url: String
    url_canonic: String
    date: String
    publication_from: String
    publication_to: String
    active: String
    promoted: Boolean
    promoted_to: String
    content_short: String
    content: String
    isFeatured: Boolean
    image_main_url: String
    image_header_url: String
  }
`;
