module.exports = `
  type Mutation {
    loginSuperadmin(email: String,password:String): [Superadmin]
    loginAdmin(email: String,password:String): [Admin]
    resetPasswordSuperadmin(email: String!): [Superadmin]
    resetPasswordAdmin(email: String!): [Admin]
    validateSuperadminToken(token:String): [Token]
    validateAdminToken(token:String): [Token]
    searchResult(query:String!): ArticleList
    sendMessageWidget15(widget_id:Int!, message:String!): [Basic]
  }
`;
