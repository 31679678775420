const Banners = [];
const LowerHome = [
  {
    type: 'partners',
    title: 'POLECAMY',
    titleColor: 'red',
    data: [
      {
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60',
        title: 'Mobi',
        link: '#',
        targetBlank: false,
        relnofollo: false,
        link_nofollow: false,
        link_blank: true
      },
      {
        image: 'https://mobischool.com.pl/_images/logo.png',
        title: 'School',
        link: '#',
        targetBlank: false,
        relnofollo: false,
        link_nofollow: false,
        link_blank: true
      },
      {
        image: 'https://mobischool.com.pl/_images/logo.png',
        title: 'Mobi',
        link: '#',
        targetBlank: false,
        relnofollo: false,
        link_nofollow: true,
        link_blank: false
      },
      {
        image: 'https://mobischool.com.pl/_images/logo.png',
        title: 'School',
        link: '#',
        targetBlank: false,
        relnofollo: false,
        link_nofollow: true,
        link_blank: true
      },
      {
        image: 'https://mobischool.com.pl/_images/logo.png',
        title: 'Mobi',
        link: '#',
        targetBlank: false,
        relnofollo: false,
        link_nofollow: true,
        link_blank: true
      },
      {
        image: 'https://mobischool.com.pl/_images/logo.png',
        title: 'School',
        link: '#',
        targetBlank: false,
        relnofollo: false,
        link_nofollow: true,
        link_blank: true
      },
      {
        image: 'https://mobischool.com.pl/_images/logo.png',
        title: 'Mobi',
        link: '#',
        targetBlank: false,
        relnofollo: false,
        link_nofollow: true,
        link_blank: true
      },
      {
        image: 'https://mobischool.com.pl/_images/logo.png',
        title: 'School',
        link: '#',
        targetBlank: false,
        relnofollo: false,
        link_nofollow: true,
        link_blank: true
      },
      {
        image: 'https://mobischool.com.pl/_images/logo.png',
        title: 'Mobi',
        link: '#',
        targetBlank: false,
        relnofollo: false,
        link_nofollow: true,
        link_blank: true
      }
    ]
  }
];
const BannersArticles = [];
const UpperHome = [
  {
    type: 'banner',
    random: undefined,
    autoplaySpeed: 3000,
    images: [
      {
        title: '',
        slug: '',
        _blank: '',
        nofollow: '',
        image: 'https://imgur.com/RLukZI5.png'
      },
      {
        title: '',
        slug: '',
        _blank: '',
        nofollow: '',
        image: 'https://imgur.com/RLukZI5.png'
      }
    ]
  },
  {
    type: 'newsgrid',
    title: 'AKTUALNOŚCI',
    linkText: 'starsze aktualności',
    slug: 'link',
    data: [
      {
        title: 'Tytuł newsa',
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      }
    ]
  }
];
const WidgetsTop = [];

const WidgetsMain = [
  {
    type: 'galleries_grid',
    title: 'NAJNOWSZE GALERIE',
    linkText: 'starsze galerie',
    slug: '/',
    data: [
      {
        title: 'Podpis galerii',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60',
        slug: '/'
      },

      {
        title: 'Podpis galerii',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60',
        slug: '/'
      },
      {
        title: 'Podpis galerii',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60',
        slug: '/'
      },
      {
        title: 'Podpis galerii',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60',
        slug: '/'
      },
      {
        title: 'Podpis galerii',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60',
        slug: '/'
      },
      {
        title: 'Podpis galerii',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60',
        slug: '/'
      }
    ]
  },
  {
    type: 'box_spacer',
    height: 80
  },
  {
    type: 'newsgrid',
    title: 'ORZYSZ TV',
    linkText: 'starsze aktualności',
    slug: 'link',
    data: [
      {
        title: 'Tytuł newsa',
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      }
    ]
  },
  {
    type: 'box_spacer',
    height: 80
  },
  {
    type: 'newsgrid',
    title: 'INNA KATEGORIA',
    linkText: 'starsze aktualności',
    isReverted: true,
    slug: 'link',
    data: [
      {
        title: 'Tytuł newsa',
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      }
    ]
  },
  {
    type: 'box_spacer',
    height: 80
  },
  {
    type: 'newsgrid',
    title: 'ORZYSZ TV',
    subtitle: 'MATERIAŁY VIDEO',
    linkText: 'starsze aktualności',
    slug: 'link',
    data: [
      {
        title: 'Tytuł newsa',
        text:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      },
      {
        title: 'Lorem ipsum dolor sit amet, consecteur adipiscing elit',
        slug: '/aktualnosci/lorem-ipsum-dolor',
        image:
          'https://images.unsplash.com/photo-1547931701-92ba1cfd0f6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=570&h=320&q=60'
      }
    ]
  }
];

const WidgetsBottom = [];

export {
  Banners,
  UpperHome,
  BannersArticles,
  WidgetsTop,
  LowerHome,
  WidgetsMain,
  WidgetsBottom
};
export default {
  Banners,
  BannersArticles,
  WidgetsTop,
  UpperHome,
  LowerHome,
  WidgetsMain,
  WidgetsBottom
};
