import dictionary from './dictionary.json';

const response = errorResponse => {
  const serverMessage =
    errorResponse &&
    errorResponse.graphQLErrors &&
    errorResponse.graphQLErrors[0] &&
    errorResponse.graphQLErrors[0].message;
  return dictionary[serverMessage] || serverMessage;
};

export default response;
