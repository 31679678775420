import {
  AppSettings as MOCK_APP_SETTINGS,
  Banners as MOCK_APP_BANNER,
  BannersArticles as MOCK_APP_BANNER_ARTICLES,
  WidgetsPopup as MOCK_APP_POPUP,
  UpperHome as MOCK_APP_UPPER_HOME,
  LowerHome as MOCK_APP_LOWER_HOME,
  WidgetsTop as MOCK_APP_TOP,
  WidgetsMain as MOCK_APP_MAIN,
  WidgetsBottom as MOCK_APP_BOTTOM,
  Article as MOCK_ARTICLE,
  ArticlesList as MOCK_ARTICLES
} from 'mocks';

export const PublicQuery = {
  getPortal: (parent, arg) => {
    return {
      name: MOCK_APP_SETTINGS.name,
      logo_url: MOCK_APP_SETTINGS.logo,
      theme: {
        name: MOCK_APP_SETTINGS.theme
      },
      mourning: MOCK_APP_SETTINGS.mourning,
      theme_mode: MOCK_APP_SETTINGS.orientation === 'invert' ? 2 : 1,
      theme_color: MOCK_APP_SETTINGS.themeColors,
      menu: MOCK_APP_SETTINGS.menu,
      footerMenu: MOCK_APP_SETTINGS.footerMenu,
      copyright: MOCK_APP_SETTINGS.copyright,
      facebook_api_key: MOCK_APP_SETTINGS.facebook_api_key,
      banner_options: MOCK_APP_SETTINGS.banner_options
    };
  },
  getNews: (parent, arg) => {
    return {
      name: MOCK_ARTICLE.title,
      meta_title: MOCK_ARTICLE.meta_title,
      meta_description: MOCK_ARTICLE.meta_description,
      url_canonic: MOCK_ARTICLE.url_canonic,
      content: MOCK_ARTICLE.data,
      image_header_url: MOCK_ARTICLE.image,
      image_main_url: MOCK_ARTICLE.image
    };
  },
  getPage: (parent, arg) => {
    return {
      name: MOCK_ARTICLE.title,
      meta_title: MOCK_ARTICLE.meta_title,
      meta_description: MOCK_ARTICLE.meta_description,
      url_canonic: MOCK_ARTICLE.url_canonic,
      content: MOCK_ARTICLE.data,
      image_header_url: MOCK_ARTICLE.image,
      image_main_url: MOCK_ARTICLE.image,
      gallery_id: '1'
    };
  },
  getGallery: (parent, arg) => {
    return {
      name: MOCK_ARTICLE.title,
      meta_title: MOCK_ARTICLE.meta_title,
      meta_description: MOCK_ARTICLE.meta_description,
      content: MOCK_ARTICLE.data,
      image_main_url: MOCK_ARTICLE.image,
      images: MOCK_ARTICLE.gallery.images.map(image => ({
        image_url: image.image,
        name: image.title,
        description: image.description
      }))
    };
  },
  getWidgetArea: (parent, arg) => {
    function switchTypeToWidgetId(type) {
      let widget_id;

      switch (type) {
        case 'icon': {
          widget_id = 3;
          break;
        }
        case 'html': {
          widget_id = 5;
          break;
        }
        case 'item': {
          widget_id = 6;
          break;
        }
        case 'news': {
          widget_id = 2;
          break;
        }
        case 'banner': {
          widget_id = 9;
          break;
        }
        case 'advertisement': {
          widget_id = 1;
          break;
        }
        case 'title': {
          widget_id = 8;
          break;
        }
        case 'lucky_number': {
          widget_id = 10;
          break;
        }
        case 'fb_page_panel': {
          widget_id = 11;
          break;
        }
        case 'list': {
          widget_id = 12;
          break;
        }
        case 'disabled_facilities': {
          widget_id = 14;
          break;
        }
        case 'contact_form': {
          widget_id = 15;
          break;
        }
        case 'iframe': {
          widget_id = 16;
          break;
        }
        case 'box_spacer': {
          widget_id = 17;
          break;
        }
        case 'gallery': {
          widget_id = 7;
          break;
        }
        case 'article': {
          widget_id = 18;
          break;
        }
        case 'vertical': {
          widget_id = 19;
          break;
        }
        case 'newsgrid': {
          widget_id = 20;
          break;
        }
        case 'partners': {
          widget_id = 21;
          break;
        }
        case 'calendar': {
          widget_id = 22;
          break;
        }
        case 'events': {
          widget_id = 23;
          break;
        }
        case 'nameday': {
          widget_id = 24;
          break;
        }
        case 'weather': {
          widget_id = 25;
          break;
        }
        case 'galleries_grid': {
          widget_id = 26;
          break;
        }
        default:
          widget_id = 0;
      }
      return widget_id;
    }

    if (arg.widget_area_id)
      switch (arg.widget_area_id) {
        case 1:
        case 28: {
          return {
            widgets: MOCK_APP_SETTINGS.socials.map(social => ({
              widget_id: switchTypeToWidgetId(social.type),
              data: social
            }))
          };
        }

        case 2: {
          return {
            widgets: MOCK_APP_SETTINGS.navMenu.map(navMenu => ({
              widget_id: switchTypeToWidgetId(navMenu.type),
              data: navMenu
            }))
          };
        }
        case 29: {
          return {
            widgets: MOCK_APP_SETTINGS.navMenuLeft.map(navMenuLeft => ({
              widget_id: switchTypeToWidgetId(navMenuLeft.type),
              data: navMenuLeft
            }))
          };
        }
        case 3: {
          return {
            widgets: MOCK_APP_SETTINGS.footer[0].map(footerColumn => ({
              widget_id: switchTypeToWidgetId(footerColumn.type),
              data: footerColumn
            }))
          };
        }
        case 4: {
          return {
            widgets: MOCK_APP_SETTINGS.footer[1].map(footerColumn => ({
              widget_id: switchTypeToWidgetId(footerColumn.type),
              data: footerColumn
            }))
          };
        }
        case 5: {
          return {
            widgets: MOCK_APP_SETTINGS.footer[2].map(footerColumn => ({
              widget_id: switchTypeToWidgetId(footerColumn.type),
              data: footerColumn
            }))
          };
        }
        case 6: {
          return {
            widgets: MOCK_APP_SETTINGS.footer[3].map(footerColumn => ({
              widget_id: switchTypeToWidgetId(footerColumn.type),
              data: footerColumn
            }))
          };
        }
        case 7: {
          return {
            widgets: MOCK_APP_POPUP.map(popup => ({
              widget_id: switchTypeToWidgetId(popup.type),
              data: popup
            }))
          };
        }
        case 8: {
          return {
            widgets: MOCK_APP_BANNER_ARTICLES.map(banners => ({
              widget_id: switchTypeToWidgetId(banners.type),
              data: banners
            }))
          };
        }
        case 31: {
          return {
            widgets: MOCK_APP_UPPER_HOME.map(upperhome => ({
              widget_id: switchTypeToWidgetId(upperhome.type),
              data: upperhome
            }))
          };
        }
        case 32: {
          return {
            widgets: MOCK_APP_LOWER_HOME.map(lowerhome => ({
              widget_id: switchTypeToWidgetId(lowerhome.type),
              data: lowerhome
            }))
          };
        }
        case 9: {
          return {
            widgets: MOCK_APP_MAIN.map(articles => ({
              widget_id: switchTypeToWidgetId(articles.type),
              data: articles
            }))
          };
        }
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15: {
          return {
            widgets: MOCK_APP_TOP.map(ad => ({
              widget_id: switchTypeToWidgetId(ad.type),
              data: ad
            }))
          };
        }
        case 16:
        case 17:
        case 18:
        case 19:
        case 20:
        case 21: {
          return {
            widgets: MOCK_APP_BOTTOM.map(ad => ({
              widget_id: switchTypeToWidgetId(ad.type),
              data: ad
            }))
          };
        }
        case 22:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27: {
          return {
            widgets: MOCK_APP_SETTINGS.widgets.map(aside => ({
              widget_id: switchTypeToWidgetId(aside.type),
              data: aside
            }))
          };
        }
        default:
          return {
            widgets: []
          };
      }
    return {
      widgets: []
    };
  },
  getBanner: (parent, arg) => {
    return [
      ...MOCK_APP_BANNER.map(banner => ({
        name: banner.title,
        isFeatured: banner.isFeatured ? true : false,
        url: banner.slug,
        image_main_url: banner.image,
        image_header_url: banner.image
      }))
    ];
  },
  searchResult: (parent, arg) => {
    return {
      max_count: MOCK_ARTICLES.pages,
      articles: [
        ...MOCK_ARTICLES.articles.map(article => ({
          name: article.title,
          url: article.slug,
          isFeatured: article.isFeatured ? true : false,
          content_short: article.text,
          image_main_url: article.image,
          image_header_url: article.image
        }))
      ]
    };
  },
  getNewsList: (parent, arg) => {
    return {
      category: { name: MOCK_ARTICLES.title },
      pages: MOCK_ARTICLES.pages,
      articles: [
        ...MOCK_ARTICLES.articles.map(article => ({
          name: article.title,
          url: article.slug,
          isFeatured: article.isFeatured ? true : false,
          content_short: article.text,
          image_main_url: article.image,
          image_header_url: article.image
        }))
      ]
    };
  }
};

export const PublicMutation = {
  setNewsletter: (parent, arg) => {
    return { done: true };
  },
  sendMessageWidget15: (parent, arg) => {
    return { done: true };
  }
};
