import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useTheme } from 'ThemeContext';

import ICON_LOADER from 'assets/Images/loader_black.svg';

import { Wrapper, Content, Icon, Text } from './LoadingData.style';

function LoadingData({ intl, fullmode = false }) {
  const theme = useTheme();
  return (
    <Wrapper theme={theme.loadingData.wrapper} fullmode={fullmode}>
      <Content>
        <Icon
          src={ICON_LOADER}
          alt={intl.formatMessage({
            id: 'LOADING_DATA.PLEASE_WAIT'
          })}
        />
        <Text theme={theme.loadingData.text}>
          <FormattedMessage id='LOADING_DATA.PLEASE_WAIT' />
          ...
        </Text>
      </Content>
    </Wrapper>
  );
}

export default injectIntl(LoadingData);
