export const config = {
  appName: process.env.REACT_APP_PAGE_TITLE,
  lang: process.env.REACT_APP_LANG || 'pl',
  theme: process.env.REACT_APP_THEME || 1,
  orientation: process.env.REACT_APP_ORIENTATION || '',
  highContrast: process.env.REACT_APP_HIGH_CONTRAST || false,
  fontSizeScale: process.env.REACT_APP_FONT_SIZE_SCALE || 1,
  mourning: process.env.REACT_APP_MOURNING || false,
  api: process.env.REACT_APP_BMS_URI,

  themes: [
    { name: 1, theme: 'themeOne' },
    { name: 2, theme: 'themeTwo' },
    { name: 3, theme: 'themeThree' },
    { name: 4, theme: 'themeFour' },
    { name: 5, theme: 'themeFive' },
    { name: 6, theme: 'themeSix' }
  ]
};
export default config;
