import React, { createContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { useCookies } from 'react-cookie';

import config from 'config';
import translations from './translations';

const Context = createContext([{}, function() {}]);

const IntlProviderWrapper = ({ children }) => {
  const [cookies, setCookie] = useCookies(['APP_LANG']);
  const [language, setLanguage] = useState(cookies['APP_LANG'] || config.lang);

  useEffect(() => {
    if (language !== cookies['APP_LANG'])
      setCookie('APP_LANG', language, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365
      });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <Context.Provider value={{ switchLanguage: setLanguage }}>
      <IntlProvider
        key={
          translations[language]
            ? language
            : translations[language.split('_')[0]]
            ? language.split('_')[0]
            : config.lang
        }
        locale={
          translations[language]
            ? language
            : translations[language.split('_')[0]]
            ? language.split('_')[0]
            : config.lang
        }
        messages={
          translations[language]
            ? translations[language]
            : translations[language.split('_')[0]]
            ? translations[language.split('_')[0]]
            : translations[config.lang]
        }
        defaultLocale={config.lang}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
